@import '~app/mixins';

.profile {
  display: flex;

  button {
    width: 130px;
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button + button {
      margin-left: 15px;
    }

    &.right {
      justify-content: right;
    }
  }
}
